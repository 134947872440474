/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Provider } from 'react-redux';
import store from './src/redux/state/store';

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    {element}
  </Provider>
);
