import { createStore } from 'redux';

const reducer = (state, action) => {
  switch (action.type) {
    // Put actions here!
    default:
      break;
  }
  return state;
};

const initialState = {};

const store = () => createStore(reducer, initialState);
export default store();
